import styles from './input.module.scss';
import iconSearch from '../../../common/images/icon_search.svg';

function Input({className, content, setContent, onSearch,}) {
    return (
        <div className={`${styles.componentInput} ${className}`}>
            <img className={styles.searchIcon} src={iconSearch} alt=""/>
            <input className={styles.inputBox}
                   placeholder="What are you looking for now?"
                   value={content}
                   onChange={({target: {value}}) => setContent(value)}
                   onKeyPress={(e) => e.key === "Enter" ? onSearch() : null}/>
        </div>
    )
}

export default Input