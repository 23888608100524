import styles from './page.module.scss';

const Page = ({className, currentPage, onSelected}) => {
    const pages = ['1', '2', '3', '4', '5', '6'];
    const page = currentPage ? currentPage : '1';

    return (
        <div className={`${styles.componentPage} ${className}`}>
            {pages.map((pageNum, i) => (
                <div key={i}
                     className={`${styles.pageItem} ${page === pageNum ? styles.selected : styles.normal}`}
                     onClick={() => page === pageNum ? null : onSelected(pageNum)}>
                    {pageNum}
                </div>
            ))}
        </div>
    )
}

export default Page