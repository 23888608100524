import styles from './submitWebsite.module.scss'

import {apiSubmitWebsite} from "../../api/submitWebsite";

import {useEffect, useState} from "react";

const SubmitWebsite = () => {
    const [content, setContent] = useState('');
    const [submitBtnText, setSubmitBtnText] = useState('提交');

    document.title = "提交站点 - CatSearch";

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    const submit = async () => {
        if (submitBtnText === '正在提交') {
            return;
        }

        const url = content.trim()
        if (url.length === 0) {
            return;
        }

        if (!url.startsWith("https://") && !url.startsWith("http://")) {
            alert("请输入合法 url");
            return;
        }

        const tmpUrl = url.replace("https://", "").replace("http://", "");
        if (tmpUrl.indexOf('/') !== -1) {
            alert("请输入合法 url");
            return;
        }
        if (tmpUrl.split(".").length < 2) {
            alert("请输入合法 url");
            return;
        }

        setSubmitBtnText('正在提交');

        const res = await apiSubmitWebsite.submit(url);
        if (res === 'success') {
            setSubmitBtnText('提交');
            alert("提交成功，后台将自动开始抓取网站内容");
            return;
        }
        if (res === 'duplicate') {
            setSubmitBtnText('提交');
            alert("该网站已经提交过了，请不要重复提交");
            return;
        }

        setSubmitBtnText('提交');
        alert("提交失败，请重新试一下");
    }

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.title}>提交站点</div>
            </header>
            <main className={styles.main}>
                <p>
                    CatSearch 支持所有人提交自己的网站，提交后，CatSearch 将在后台自动抓取网站的所有页面并收录，届时可以在本搜索引擎中进行搜索。😺
                </p>
                <p>事实上，CatSearch 是一个专注于个人站点的搜索引擎，主要抓取个人博客，同时使用 Google、Bing
                    等搜索引擎作为辅助。</p>

                <div className={styles.submitContainer}>
                    <div className={styles.submitDesc}>
                        <p>网站 url
                            必须带 <strong>"http://"</strong> 或 <strong>"https://"</strong> 前缀，且<strong>不能</strong>带路径，例如：
                        </p>
                        <p>允许：http://woc.cool</p>
                        <p>允许：http://www.woc.cool</p>
                        <p>允许：https://woc.cool</p>
                        <p>允许：https://this.test.woc.cool</p>
                        <p><strong>不允许：</strong>www.woc.cool</p>
                        <p><strong>不允许：</strong>https://www.woc.cool/</p>
                        <p><strong>不允许：</strong>https://www.woc.cool/search</p>
                        <p><strong>不允许：</strong>https://www.woc.cool/search?q=xxx</p>
                    </div>
                    <div className={styles.submitBox}>
                        <input className={styles.inputBox}
                               placeholder="这里输入网站 url"
                               value={content}
                               onChange={({target: {value}}) => setContent(value)}/>
                        <div className={styles.submitBtn} onClick={submit}>{submitBtnText}</div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default SubmitWebsite