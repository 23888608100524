import {NavigateFunction} from "react-router-dom";
import queryString from 'query-string';

export const goto = {
    home: (nav: NavigateFunction) => {
        nav("/");
    },
    search: (nav: NavigateFunction, {kw, page}) => {
        nav({
            pathname: '/search',
            search: queryString.stringify({
                q: kw,
                page
            })
        });
    },
    deploy: (nav: NavigateFunction) => {
        nav("/deploy");
    },
    submitWebsite: (nav: NavigateFunction) => {
        nav("/submitWebsite");
    }
}