import "./common/styles/frame.css";

import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import {BrowserView, isMobile, MobileView} from 'react-device-detect';

import {globalMobileRouters, globalRouters} from "./route";
import {store} from "./store";

if (isMobile) {
    import("./common/styles/mobileGlobal.css")
} else {
    import("./common/styles/pcGlobal.css")
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserView className="root-stable-view">
            <RouterProvider router={globalRouters} />
        </BrowserView>
        <MobileView className="root-stable-view">
            <RouterProvider router={globalMobileRouters} />
        </MobileView>
    </Provider>
);

console.log('%c CatSearch %c woc.cool','background-color:rgb(53,73,94);color: #fff;border-radius:2px 0 0 2px;padding:2px 4px;','background-color:rgb(25, 115, 232);color: #fff;border-radius:0 2px 2px 0;padding:2px 4px;font: 9pt "Apercu Regular", Georgia, "Times New Roman", Times, serif;');