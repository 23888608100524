import styles from './home.module.scss';
import logo from '../../common/images/lottie/logo.json';

import {goto} from "../../api";

import Input from "../../components/common/input/input";
import Header from "../../components/common/header/header";

import Lottie from "lottie-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const nav = useNavigate();
    const [content, setContent] = useState('');

    const search = () => {
        const kw = content.trim();
        if (kw.length === 0) {
            return;
        }

        goto.search(nav, {kw: kw});
    };

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    document.title = "CatSearch";

    return (
        <>
            <header>
                <Header className={styles.headerOption}/>
            </header>
            <main className={styles.main}>
                <Lottie className={styles.logo} animationData={logo} loop={true} autoplay={true}/>
                <Input className={styles.input} content={content} setContent={setContent} onSearch={search}/>
            </main>
        </>
    )
}

export default Home