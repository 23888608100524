let API_BASE_URL = 'https://spider.search.woc.cool/';
if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = 'https://spider.search.woc.cool/';
}

export const apiSubmitWebsite = {
    submit: async (rawUrl) => {
        if (!rawUrl) {
            return null;
        }

        const url = rawUrl.trim();
        if (!url) {
            return null;
        }

        const res = await fetch(API_BASE_URL + 'addSite', {
            method: 'POST',
            body: JSON.stringify({
                url: url
            })
        })
        if (!res.ok) {
            return 'error';
        }

        try {
            const data = await res.json();
            if (data.code !== 0) {
                if (data.code === 200) {
                    return 'duplicate';
                }
                return 'error';
            }

            return 'success';
        } catch (e) {
            return 'error';
        }
    }
}