import styles from './loading.module.scss';

import {useEffect, useState} from "react";

const Loading = ({className}) => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        let counter = 0;
        let i = setInterval(() => {
            counter++;
            setPercent(value => value + 1);

            if (counter === 100) {
                clearInterval(i);
            }
        }, 50);

        return () => {
            clearInterval(i);
        };
    }, []);

    return (
        <div className={`${styles.componentLoading} ${className}`}>
            <p>loading</p>
            <h1>{percent}%</h1>
            <hr style={{width: `${percent}%`}}/>
        </div>
    )
}

export default Loading