import styles from './card.module.scss';

import {Fragment} from "react";

const Card = ({className, type, data}) => {
    let title, node;
    switch (type) {
        case 0:
            title = "推荐结果";
            node = (
                <>
                    <div className={styles.tag}>
                        <div className={styles.tagItem}>
                            <div className={styles.tagIndicator}></div>
                            <a href={data.url} target="_blank">
                                <div className={styles.tagContent}>{data.title}</div>
                            </a>
                        </div>
                    </div>
                    <div className={styles.content}>{data.ans.map((item, i) => (
                        <Fragment key={i}>
                            {item}
                            {i !== data.ans.length - 1 && <br/>}
                        </Fragment>
                    ))}</div>
                </>
            )
            break;
        case 1:
            title = "百科";
            node = (
                <>
                    <div className={styles.tag}>
                        <div className={styles.tagItem}>
                            <div className={styles.tagIndicator}></div>
                            <div className={styles.tagContent}>{data.title}</div>
                        </div>
                        <div className={styles.tagItem}>
                            <div className={styles.tagIndicator}></div>
                            <div className={styles.tagContent}>{data.category}</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        {data.content}
                        <br/>
                        {data.info && Object.keys(data.info).map(k => (
                            <Fragment key={k}>
                                <br/>
                                {k}：{data.info[k]}
                            </Fragment>
                        ))}
                    </div>
                </>
            )
            break;
        default:
            title = "";
            node = <></>
            break;
    }

    return (
        <div className={`${styles.componentCard} ${className}`}>
            <div className={styles.title}>{title}</div>
            <div className={styles.wrap}>{node}</div>
        </div>
    )
}

export default Card