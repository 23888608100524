import {createBrowserRouter, Navigate} from 'react-router-dom';

import Search from "../pages/search/search";
import Home from "../pages/home/home";
import Deploy from "../pages/deploy/deploy";
import SubmitWebsite from "../pages/submitWebsite/submitWebsite";

import SearchMobile from "../pages/mobile/search/search";
import HomeMobile from "../pages/mobile/home/home";
import DeployMobile from "../pages/mobile/deploy/deploy";

export const globalRouters = createBrowserRouter([
    {
        path: '/submitWebsite',
        element: <SubmitWebsite/>
    },
    {
        path: '/deploy',
        element: <Deploy/>
    },
    {
        path: '/search',
        element: <Search/>
    },
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    }
]);

export const globalMobileRouters = createBrowserRouter([
    {
        path: '/deploy',
        element: <DeployMobile/>
    },
    {
        path: '/search',
        element: <SearchMobile/>
    },
    {
        path: '/',
        element: <HomeMobile/>
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    }
]);