import styles from './search.module.scss';
import logo from '../../common/images/lottie/logo2.json';

import {goto} from "../../api";
import {apiRequest} from "../../api/search";

import Input from "../../components/common/input/input";
import Item from "../../components/search/item/item";
import Page from "../../components/search/page/page";
import Card from "../../components/search/card/card";
import Loading from "../../components/common/loading/loading";
import Header from "../../components/common/header/header";

import Lottie from "lottie-react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import queryString from "query-string";
import {storage} from "../../api/storage";

const Search = () => {
    const location = useLocation();
    const nav = useNavigate();

    const params = queryString.parse(location.search);

    const [keywords, setKeywords] = useState(params.q);
    const [searchData, setSearchData] = useState(null);
    const [aiInputting, setAIInputting] = useState('');
    const [aiContent, setAIContent] = useState('');
    const [searchFinished, setSearchFinished] = useState(false);
    const [headerStyle, setHeaderStyle] = useState({});

    const requestSearch = async () => {
        setSearchFinished(false);

        const page = params.page;
        const data = await apiRequest.search({kw: keywords, page: page});
        if (!data) {
            goto.home(nav);
            return;
        }

        setSearchFinished(true);
        setSearchData(data);

        if (data !== 'rateLimit' && data !== 'error') {
            aiChat();
        }
    };

    const aiChat = () => {
        if (params.page && params.page !== "1") {
            return;
        }

        apiRequest.aiChat(keywords, {
            onOpen: () => {
                setAIInputting("(正在输入中...)");
                setAIContent("");
            },
            onMessage: (text) => {
                setAIContent(value => {
                    let newAIContent = value + text;
                    if (newAIContent.startsWith('\n')) {
                        newAIContent = newAIContent.replace('\n', '');
                    }
                    return newAIContent;
                });
            },
            onClose: () => {
                setAIInputting("");
            },
            onError: () => {
                setAIInputting("");
                setAIContent("AI 出错啦，暂时不能回答问题了～");
            }
        })
    };

    const search = ({page}) => {
        const kw = keywords.trim();
        if (kw.length === 0) {
            return;
        }

        goto.search(nav, {kw: kw, page});
        window.location.reload();
    };

    const handleScroll = () => {
        const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (top === 0) {
            setHeaderStyle({
                backgroundColor: 'white',
                borderBottom: 'none'
            });
        } else {
            setHeaderStyle({
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderBottom: '#e7e7ee solid 1px'
            });
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = '#fafaff';
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        requestSearch();
    }, []);

    document.title = params.q + " - CatSearch";

    let node;
    if (searchFinished) {
        if (searchData === 'rateLimit') {
            node = (
                <div className={styles.error}>
                    搜索频率太快了！请慢点！不要滥用！！
                </div>
            )
        } else if (searchData === 'error') {
            node = (
                <div className={styles.error}>
                    <p>搜索引擎出错了，重新试一下吧～</p>
                    <p><a href="https://status.woc.cool">或点击这里查看服务器运行状态</a></p>
                </div>
            )
        } else {
            node = (
                <>
                    <main className={styles.main}>
                        <div className={styles.wrapLeft}>
                            <div className={styles.resultList}>
                                {searchData && searchData.hasAnswer && <Card type={0} data={searchData.result.answer}/>}
                                {searchData && searchData.hasCard && <Card type={1} data={searchData.result.card}/>}
                                {searchData && searchData.result.results.map((item, i) => <Item key={i} {...item}/>)}
                            </div>
                        </div>
                        <div className={styles.wrapRight}>
                            <div className={styles.aiAnswerContainer}>
                                <div className={styles.title}>AI 智能回答 {aiInputting}</div>
                                <div className={styles.feedback}
                                     onClick={() => apiRequest.uploadAIFeedback(keywords, aiContent)}>
                                    回答结果不满意？点我反馈
                                </div>
                                <div className={styles.content}>{aiContent.split('\n').map((line, i) => (
                                    <Fragment key={i}>
                                        {line}
                                        {i !== aiContent.length - 1 && <br/>}
                                    </Fragment>
                                ))}</div>
                            </div>
                        </div>
                    </main>
                    <footer className={styles.footer}>
                        <Page className={styles.page}
                              currentPage={params.page}
                              onSelected={(page) => search({page: page})}/>
                    </footer>

                    <div className={styles.notification}
                         style={{display: storage.shouldShowNotify() ? "block" : "none"}}
                         onClick={() => storage.setShouldShowNotify(false)}>
                        <p>现已支持所有人提交网站用于收录</p>
                        <p>提交入口在电脑端网页右上角</p>
                    </div>
                </>
            )
        }
    } else {
        node = (
            <div className={styles.loading}>
                <Loading/>
            </div>
        )
    }

    return (
        <>
            <header className={styles.header} style={headerStyle}>
                <div className={styles.headerMain}>
                    <div className={styles.headerMainSearch}>
                        <Link to="/">
                            <Lottie className={styles.logo}
                                    animationData={logo}
                                    loop={false}
                                    autoplay={true}/>
                        </Link>
                        <Input className={styles.input}
                               content={keywords}
                               setContent={setKeywords}
                               onSearch={() => search({})}/>
                    </div>
                    <div className={styles.headerMainOption}>
                        <Header/>
                    </div>
                </div>
                <div className={styles.sub}>
                    {/*{searchData && searchData.result && searchData.result.source.map((item, i) => (*/}
                    {/*    <div key={i} className={styles.sourceItem}>{item}</div>*/}
                    {/*))}*/}
                    {searchData && searchData.tookMs &&
                        <div className={styles.sourceItem}>耗时: {searchData.tookMs.total / 1000}s</div>}
                </div>
            </header>
            {node}
        </>
    )
}

export default Search