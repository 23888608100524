import styles from './header.module.scss';

import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import {goto} from "../../../api";

const Header = ({className}) => {
    const nav = useNavigate();

    return (
        <div className={`${styles.componentHeader} ${className}`}>
            <div className={isMobile ? styles.buttonMobile : styles.button} onClick={() => goto.submitWebsite(nav)}>
                提交站点
            </div>
            <div className={isMobile ? styles.buttonMobile : styles.button} onClick={() => goto.deploy(nav)}>
                私有部署
            </div>
        </div>
    )
}

export default Header