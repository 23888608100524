import {fetchEventSource} from "@microsoft/fetch-event-source";
// import {isMobile} from "react-device-detect";

let API_BASE_URL = 'https://api.search.woc.cool/';
if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = 'https://api.search.woc.cool/';
}

export const apiRequest = {
    search: async ({kw, page}) => {
        if (!kw) {
            return null;
        }

        const keywords = kw.trim();
        if (!keywords) {
            return null;
        }

        const pageNum = page ? page : '1';

        try {
            const res = await fetch(API_BASE_URL + 'search/engine', {
                method: 'POST',
                body: JSON.stringify({
                    q: keywords,
                    page: pageNum
                })
            })
            if (!res.ok) {
                if (res.status === 429) {
                    return 'rateLimit';
                }
                return 'error';
            }

            const data = await res.json();
            if (data.code !== 0) {
                return 'error';
            }

            // if (pageNum === '1') {
            // apiRequest.uploadKw(keywords);
            // }

            return data.data;
        } catch (e) {
            return 'error';
        }
    },
    aiChat: (kw, {onOpen, onMessage, onClose, onError}) => {
        fetchEventSource(API_BASE_URL + 'chat/conversation', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/event-stream'
            },
            body: JSON.stringify({
                prompt: kw
            }),
            onopen: onOpen,
            onmessage: (res) => {
                onMessage(JSON.parse(res.data).text);
            },
            onerror: () => onError(),
            onclose: onClose
        }).catch(() => onError())
    },
    // uploadKw: (kw) => {
    //     let ua = 'pc';
    //     if (isMobile) {
    //         ua = 'mob'
    //     }
    //     appWriteDatabase.createDocument('6400dc2b05f4fd2789ff', '6400dc61af99ec7c1535', ID.unique(), {
    //         kw: kw,
    //         ip: '0.0.0.0',
    //         ua: ua
    //     })
    // },
    uploadAIFeedback: (kw, ans) => {
        // appWriteDatabase.createDocument('6400dc2b05f4fd2789ff', '6401de097d732031a920', ID.unique(), {
        //     kw: kw,
        //     answer: ans
        // });
        alert("反馈成功，将持续优化 AI 功能");
    }
}