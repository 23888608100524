import styles from './item.module.scss';

const Item = (props: { className: string, url: any, title: string, content: string }) => {
    return (
        <div className={`${styles.resultListItem} ${props.className}`}>
            <a href={props.url.raw} target="_blank" rel="noreferrer">
                <div className={styles.url}>{props.url.parse.host + props.url.parse.path}</div>
            </a>
            <a href={props.url.raw} target="_blank" rel="noreferrer">
                <div className={styles.title} dangerouslySetInnerHTML={{__html: props.title}}></div>
            </a>
            <div className={styles.content} dangerouslySetInnerHTML={{__html: props.content}}></div>
        </div>
    )
}

export default Item