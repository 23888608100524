import styles from './deploy.module.scss';

import {useEffect} from "react";

const DeployMobile = () => {
    document.title = "私有部署 - CatSearch";

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.title}>私有化部署</div>
            </header>
            <main className={styles.main}>
                <p>
                    CatSearch 后续将支持所有用户免费独立部署到服务器。待未来搜索服务完善后，也会有开源的可能，目前程序还不完善，Bug也不少，还要再改改。😹
                </p>
                <p>目前有Bug、建议、想法的话可以加QQ群反馈：457800093</p>

                <ul>
                    <li>
                        原始二进制文件部署
                        <p>后续开放</p>
                    </li>
                    <li>
                        Docker 部署
                        <p>后续开放</p>
                    </li>
                    <li>
                        一键部署
                        <p>后续开放</p>
                    </li>
                </ul>
            </main>
        </div>
    )
}

export default DeployMobile